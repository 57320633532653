import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router';
import PrivacyPolicy from './PrivacyPolicy'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div>404 Not Found {`:(`}</div>
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /*<React.StrictMode>
    <App />
  </React.StrictMode>*/
  <div className='min-h-screen w-full flex items-center justify-center p-4'>
    <RouterProvider router = {router} />
  </div>
);