import './privacy.css'

function PrivacyPolicy() {

  return (
    <div className="privacy-policy">
      <div className='privacy-content'>
        <header className='header-container'>
          <div className='img-container'>
            <img className="img-wrapper" src="https://tqmdykzmocnsnvaubpdb.supabase.co/storage/v1/object/public/integration_logos//notifs-logo.png" alt="logo"/>
          </div>
          <h1 className='title-container'>Privacy Policy</h1>
        </header>

        <section>
          <h2>Notifs' Commitment to Privacy</h2>
          <p className='privacy-text'>Hi! Thank you for using Notifs. We hope it helps you save time and stay focused. Here's some information explaining the types of data that Notifs collects and how it is used.</p>
          <h3>Information We Collect</h3>
          <p className='privacy-text'> Account Information - We collect your email address and passwordwhen you sign up.</p>
          
          <p className='privacy-text'>Notification Data - We store notifications from your connected accounts (e.g., Linear, Slack) until you delete them.</p>
          
          <p className='privacy-text'> Usage Data - We use <a href="https://posthog.com/" target="_blank" rel="noreferrer">Posthog</a> to collect anonymized usage data to improve our service.</p>
        </section>

        <section>
          <h3>How We Use Your Information</h3>
          <ul>
            <li>To create and manage your account.</li>
            <li>To provide and improve our service.</li>
            <li>To authenticate users and manage sessions.</li>
          </ul>
        </section>

        <section>
          <h3>Data Storage and Security</h3>
          <p className='privacy-text'>All data is stored securely using <a href="https://supabase.com/" target="_blank" rel="noreferrer">Supabase</a>. Sessions are managed in browser local storage and Chrome local storage.</p>
        </section>

        <section>
          <h3>Third-Party Services</h3>
          <ul>
            <li>Supabase- Backend storage, authentication, and session management.</li>
            <li>Posthog- Usage analytics (aggregated and anonymized).</li>
          </ul>
        </section>

        <section>
          <h3>Cookies and Tracking</h3>
          <p className='privacy-text'>We do not use cookies for tracking, but Supabase may use them for authentication, and Posthog may use them to collect anonymized usage data. You can manage or disable cookies through your browser settings.</p>
        </section>


        <section>
          <h3>Data Retention and Deletion</h3>
          <p className='privacy-text'>You can delete your account and associated data anytime through the Notifs web app or Chrome extension. If you would like a copy of your data, please contact us at <a href="mailto:bobby@getnotifs.com">bobby@getnotifs.com</a>. This feature is not currently automated, but we will work with you to fulfill the request.</p>
        </section>

        <section>
            <h3>Changes to This Policy</h3>
            <p className='privacy-text'>We may update this policy from time to time. If we do, we will notify you of significant changes. Continuing to use Notifs means you accept the updated policy.</p>
        </section>

        <section>
            <h3>Contact</h3>
            <p className='privacy-text'>If you have questions, email us at <a href="mailto:bobby@getnotifs.com">bobby@getnotifs.com</a>.</p>
        </section>

      </div>
      
    </div>
  );
}

export default PrivacyPolicy;
